// src/components/Donate.js
import React from 'react';

function Donate() {
  return (
    <section id="donate" className="donate">
      <h2>Support Our Cause</h2>
      <p>Your donations help us continue our work in supporting animals in need. Every contribution, big or small, makes a difference.</p>

      {/* PayPal Donation Button */}
      <strong><p>Donate via gofundme</p></strong>
      <div style={{ textAlign: 'center' }}>
        <a href="https://www.paypal.com/US/fundraiser/charity/5301004" target="_blank" rel="noopener noreferrer">
          <img
            src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
            alt="Donate with PayPal button"
            style={{ width: '300px', height: '100px' }}  // Adjust width and height as needed
          />
        </a>
      </div>

      {/* GoFundMe Button */}
      <br /><br /><strong><p>Donate via gofundme</p></strong>
      <a href="https://www.gofundme.com/f/help-spca-nepal-to-spay-neuter-street-animals" target="_blank" rel="noopener noreferrer" className="gofundme-button">
        Spay Neuter Street Animals
      </a><br />
      <a href="https://www.gofundme.com/f/help-spca-nepal-smooth-relocation-to-save-animals" target="_blank" rel="noopener noreferrer" className="gofundme-button">
        SPCA Nepal relocation
      </a><br />
      <br /><p><strong>Zelle us at </strong><a href="mailto:sahayogspcanepal@gmail.com">sahayogspcanepal@gmail.com</a></p>
    </section>
  );
}

export default Donate;
