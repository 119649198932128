
import React from 'react';

function Home() {
  return (
    <>
      <header className="header">
        <nav>
          <div className="logo-container">
            <img src="/images/logo.jpg" alt="Sahayog Logo" className="logo-image" />
          </div>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About Us</a></li>
            <li><a href="#team">Our Team</a></li>
            <li><a href="#donate">Donate</a></li>
            <li><a href="#contact">Contact us</a></li>
          </ul>
        </nav>
      </header>
      <section id="home" className="home">
        <h2>Welcome to Sahayog </h2>
        <p>We are a non-profit organization committed to making a positive impact on the lives of animals in Nepal. As a 501(c)(3) tax-exempt organization, your contributions are fully tax-deductible, helping us maximize our efforts. Explore our website to learn more about our mission, our projects, and how you can get involved.</p>
      </section>
    </>
  );
}

export default Home;
