// src/App.js
import React from 'react';
import Home from './components/Home';
import About from './components/About';
import TeamGallery from './components/TeamGallery';
import Donate from './components/Donate'; 
import Contact from './components/Contact';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <div className="App">
      <Home />
      <About />
      <Donate />
      <TeamGallery />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
