// src/components/Contact.js
import React from 'react';

function Contact() {
  return (
    <>
      <section id="contact" className="contact">
        <h2>Contact Us</h2>
        <p>We'd love to hear from you! Whether you're interested in volunteering, donating, or just want to learn more, feel free to reach out at <a href="mailto:sahayogspcanepal@gmail.com">sahayogspcanepal@gmail.com</a></p>
        {/* <form>
          <label>
            Name:
            <input type="text" name="name" required />
          </label>
          <label>
            Email:
            <input type="email" name="email" required />
          </label>
          <label>
            Message:
            <textarea name="message" required></textarea>
          </label>
          <button type="submit">Send</button>
        </form>
        <br /> */}
      </section>
      <section className="social-media">
        <h3>Our Social Media Links</h3>
        <ul>
          <li><a href="https://www.facebook.com/profile.php?id=61565142598149" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i> Facebook</a></li>
          <li><a href="https://www.instagram.com/sahayogthehelp/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i> Instagram</a></li>
        </ul>
      </section>
    </>
  );
}

export default Contact;
