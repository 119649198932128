// src/components/Footer.js
import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <p>
        &copy; 2024 Sahayog. All rights reserved. Designed by{" "}
        <a
          href="https://www.linkedin.com/in/aachal-thapa-3742b692/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "white" }}>
          Aachal Thapa
        </a>
      </p>

      <nav>
        <ul>
          <li><a href="#home">Home</a></li>
          <li><a href="#about">About Us</a></li>
          <li><a href="#team">Our Team</a></li>
          <li><a href="#donate">Donate</a></li>
          <li><a href="#contact">Contact us</a></li>
        </ul>
      </nav>
    </footer>
  );
}

export default Footer;
