// src/components/TeamGallery.js
import React from 'react';

function TeamGallery() {
  const teamMembers = [
    { name: 'Dristi Ghimire', role: 'President', imageUrl: 'images/dristi.jpg' },
    { name: 'Ada Thapa', role: 'Vice President', imageUrl: 'images/ada.jpg' },
    { name: 'Anjela Manandhar', role: 'Secretary', imageUrl: 'images/anjela.jpg' },
    { name: 'Monika Shah', role: 'Treasurer', imageUrl: 'images/monika.jpg' },
    { name: 'Prabin Subedi', role: 'Board Member', imageUrl: 'images/prabin.jpg' },
    { name: 'Aachal Thapa', role: 'Board Member', imageUrl: 'images/aachal.jpg' }
  ];
  return (
    <section id="team" className="team-gallery">
      <h2>Meet Our Team</h2>
      <div className="team-members">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member">
            <img src={member.imageUrl} alt={`${member.name}`} />
            <h3>{member.name}</h3>
            <p>{member.role}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default TeamGallery;
