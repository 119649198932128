import React from 'react';

function About() {
  return (
    <section id="about" className="about">
      <h2>About Sahayog</h2>
      <p>Sahayog is a newly founded non-profit organization, driven by a passionate group of animal lovers living in the USA, united by our love for animals and our deep connection to Nepal. Our mission is to support and care for animals in Nepal by providing a safe haven for those in need and educating the community on the importance of animal welfare and environmental conservation. Although we are just beginning our journey, our vision is clear: to create a world where every animal is treated with respect and kindness. Join us as we work together to make a difference. Our vision is to create a world where every animal is treated with respect and kindness. Join us in making a difference.</p>
    </section>
  );
}

export default About;
